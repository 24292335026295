import React, { useEffect } from 'react';
import { config } from '@abyss/web/tools/config';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { event } from '@abyss/web/tools/event';
import { PageHeader } from 'src/app/App/PageHeader';
import { PageLayout } from 'src/app/App/PageLayout';
import { styles } from './ComeBackLater.styles';

export const ComeBackLater = () => {
  const classes = useStyles(styles);

  useEffect(() => {
    event('NOT_ELIGIBLE_PAGE_VIEW');
  }, []);

  return (
    <React.Fragment>
      <PageHeader showBackButton={false}>
        <PageHeader.Title>
          We're sorry, you are currently not eligible.
        </PageHeader.Title>
      </PageHeader>
      <PageLayout>
        <div className={classes.content}>
          Only billing entities that meet the criteria listed on the previous
          page are eligible. If you believe there is an error, or for questions
          regarding the distribution of funds, please visit{' '}
          <a
            href={config('CORONAVIRUS_GOV_URL')}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.accessiblityLink}
          >
            hrsa.gov/provider-relief
          </a>{' '}
          or call the toll-free Provider Support Line at (866) 569-3522. Hours
          of operation are 8 a.m. to 8 p.m. Central Time, Monday through Friday.
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
