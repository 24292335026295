import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { FooterLinks } from '@hhs/ui/src/common/FooterLinks';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { styles } from './Content.styles';
import { Tranche1 } from './routes/Tranche1';

const Content = () => {
  const classes = useStyles(styles);

  return (
    <main id="main-content" className={classes.content}>
      <Router.Routes title="{{title}} | CARES Provider Relief Fund">
        <Router.Route
          title="Contact Us"
          path="/contact-us"
          element={< FooterLinks.ContactUs />}
        />
        <Router.Route
          title="Terms Of Use"
          path="/terms-of-use"
          element={< FooterLinks.TermsOfUse />}
        />
        <Router.Route
          title="Privacy Policy"
          path="/privacy-policy"
          element={< FooterLinks.PrivacyPolicy />}
        />
        <Router.Route
          title="Accessibility Statement"
          path="/accessibility-statement"
          element={<FooterLinks.AccessibilityStatement />}
        />
        <Router.Route index path="/*" title="Relief Fund Request" element={<Tranche1 />} />
      </Router.Routes>
    </main>
  );
};

export { Content };
