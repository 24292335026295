import React from 'react';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { App } from './App';
import { theme } from '../styles/theme';
import { configureAppStore } from '../state/store';
import { AppProvider } from '@hhs/ui/old-abyss/ui/base/AppProvider';

export const store = configureAppStore();  

export const browser = () => {
  return (  
    <AppProvider store={store}>
        <AbyssProvider includeBaseCss={false} theme={theme}>
          <App />
        </AbyssProvider>
    </AppProvider>
  )
};